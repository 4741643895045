import { darkColors, lightColors } from '../../theme/colors'
import { NavTheme } from './types'

export const light: NavTheme = {
  background: lightColors.transparent,
  hover: '#ffffff',
}

export const dark: NavTheme = {
  background: darkColors.transparent,
  hover: '#ffffff',
}
