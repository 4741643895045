import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 128 128" {...props}>
<g transform="translate(0.000000,128.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M482 1260 c-226 -59 -404 -239 -462 -466 -87 -338 124 -687 466 -774
338 -87 687 124 774 466 56 217 -8 442 -170 604 -162 162 -392 226 -608 170z
m353 -44 c184 -63 326 -205 386 -388 34 -105 34 -271 0 -376 -43 -131 -127
-241 -245 -320 -107 -72 -194 -97 -336 -97 -142 0 -229 25 -337 97 -128 86
-214 209 -253 364 -36 141 -20 279 50 422 77 156 235 278 410 317 96 22 226
14 325 -19z"/>
<path d="M501 1164 c-180 -48 -339 -208 -386 -389 -19 -75 -19 -182 0 -264 56
-234 281 -411 525 -411 294 0 540 246 540 540 0 244 -177 469 -411 525 -80 18
-197 18 -268 -1z m264 -29 c225 -59 385 -264 385 -495 0 -329 -318 -577 -635
-495 -382 100 -514 577 -235 855 126 126 316 179 485 135z"/>
<path d="M600 1000 l0 -40 -40 0 -40 0 0 -40 0 -40 -40 0 -40 0 0 -40 0 -40
-40 0 -40 0 0 -40 0 -40 -40 0 -41 0 3 -117 3 -118 37 -3 c35 -3 37 -5 40 -40
l3 -37 78 -3 77 -3 0 41 0 40 40 0 40 0 0 -80 0 -80 -41 0 -40 0 3 -37 3 -38
115 0 115 0 3 38 3 37 -40 0 -41 0 0 85 0 85 40 0 c40 0 40 0 40 -39 l0 -38
70 -7 c38 -4 74 -4 80 -1 5 3 10 21 10 40 0 32 2 34 38 37 l37 3 0 115 0 115
-37 3 c-35 3 -37 5 -40 40 -3 35 -5 37 -40 40 -35 3 -37 5 -40 40 -3 35 -5 37
-40 40 -35 3 -37 5 -40 40 -3 35 -5 37 -40 40 -37 3 -38 4 -38 43 0 39 0 39
-40 39 l-40 0 0 -40z"/>
</g>
    </Svg>
  )
}

export default Icon
