import styled, { DefaultTheme } from 'styled-components'
import getColor from '../../util/getColor'
import { TagProps } from './types'

interface ThemedProps extends TagProps {
  theme: DefaultTheme
}

const getThemeTextColor = ({ outline, variant = 'textSubtle', theme }: ThemedProps) =>
  outline ? getColor(variant, theme) : '#ffffff'

export const StyledTag = styled.div<ThemedProps>`
  align-items: center;
  background-color: transparent;
  border: 2px solid white;
  border-radius: 16px;
  color: #00E0A0;
  display: inline-flex;
  font-size: 14px;
  font-weight: 400;
  height: 28px;
  line-height: 1.5;
  padding: 0 8px;
  white-space: nowrap;

  svg {
    fill: ${getThemeTextColor};
  }
`

export default null
