import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 36 36" {...props}>
<g transform="translate(-6.500000,36.000000) scale(0.100000,-0.100000)"
fill="#0DEAEA" stroke="none">
<path d="M230 345 c0 -51 -2 -55 -24 -55 -13 0 -29 -7 -36 -15 -11 -13 -8 -15
19 -15 l31 0 0 -77 c1 -43 7 -91 14 -108 l12 -30 12 34 c7 19 12 67 12 107 0
73 0 74 26 74 22 0 25 2 14 15 -7 8 -21 15 -31 15 -16 0 -19 8 -19 55 0 42 -3
55 -15 55 -12 0 -15 -13 -15 -55z m17 -187 c-2 -24 -4 -7 -4 37 0 44 2 63 4
43 2 -21 2 -57 0 -80z"/>
<path d="M36 357 c3 -12 14 -31 23 -41 9 -11 10 -16 4 -12 -17 10 -16 1 3 -35
10 -20 33 -39 67 -54 28 -13 53 -23 55 -21 9 10 -12 55 -30 63 -20 9 -19 10
13 31 19 12 31 22 27 22 -3 0 -43 16 -88 35 -79 34 -81 34 -74 12z m110 -47
c2 0 4 -5 4 -10 0 -6 -18 -1 -40 10 -50 25 -52 35 -4 15 20 -8 38 -15 40 -15z
m14 -82 c0 -10 -56 18 -65 32 -4 6 10 3 29 -7 20 -9 36 -20 36 -25z"/>
<path d="M362 343 l-83 -36 32 -21 c31 -19 31 -20 11 -29 -18 -8 -39 -53 -30
-63 2 -2 27 8 55 21 34 15 57 34 67 54 19 36 20 45 4 35 -7 -4 -6 1 3 12 15
17 35 65 27 63 -2 0 -40 -17 -86 -36z m48 -8 c0 -2 -18 -14 -40 -25 -22 -11
-40 -16 -40 -11 0 5 17 16 38 24 44 19 42 18 42 12z m-39 -85 c-13 -11 -31
-20 -40 -20 -9 1 -1 9 19 20 45 24 51 24 21 0z"/>
<path d="M90 215 c0 -20 28 -54 60 -73 49 -28 52 -28 48 5 -2 22 -13 31 -55
50 -29 12 -53 21 -53 18z"/>
<path d="M343 194 c-44 -19 -53 -27 -53 -48 0 -32 1 -32 50 -4 33 19 68 64 58
73 -2 2 -27 -8 -55 -21z"/>
</g>
    </Svg>
  )
}

export default Icon
